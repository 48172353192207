<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <h1>
              Listado de pedidos (vendido - confirmado) - detallado
            </h1>
          </td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2">Pedido (vendido)</td>
          <td colspan="2">Pedido (confirmado)</td>
        </tr>
        <tr class="border-b">
          <td class="w-1/12">N pedido</td>
          <td class="w-1/12">Fecha pedido</td>
          <td class="w-4/12">Tienda</td>
          <td class="w-1/12">N. prendas</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">N. Prendas</td>
          <td class="w-1/12">%</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">%</td>
        </tr>
      </thead>
      <tr v-for="coleccion in report.colecciones" :key="coleccion.descripcionColeccion">
        <td colspan="9" class="bloque leading-none">
          <table :class="{ 'border-2 border-gray-800 font-semibold': detail }">
            <tr>
              <td class="w-6/12">
                {{ coleccion.descripcionColeccion }} ({{
                  coleccion.numPedidos
                }})
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.totalPrendasVendidas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.totalImporteVendido }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.totalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.porcTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.totalImporteConfirmado }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.porcTotalImporteConfirmado }}
              </td>
            </tr>
          </table>
          <table class="mb-5 leading-1 print:leading-3" v-if="detail">
            <tr v-for="pedido in coleccion.pedidos" :key="pedido.n_pedido">
              <td class="w-1/12 p-1">
                {{ pedido.numPedido }}
              </td>
              <td class="w-1/12 p-1">
                {{ pedido.fechaPedido }}
              </td>
              <td class="w-4/12 p-1">
                {{ pedido.cliente }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.prendasVendidas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.importeVendido }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.prendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.porcPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.importeConfirmado }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.porcImporteConfirmado }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="9">
          <table>
            <tr>
              <td class="w-6/12 p-1">
                TOTAL GENERAL
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.totalPrendasVendidas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.totalImporteVendido }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.totalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.porcTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.totalImporteConfirmado }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.porcTotalImporteConfirmado }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <!--<div class="footer p-10">
    <table class="footer border-t">
      <tr>
        <td class="w-3/8 font-semibold">CIA DE LA MODA, B.G., S.L.</td>
        <td class="w-2/8"></td>
        <td class="w-3/8">Sede Legal: Calle Libertad, 15 bajo 28004-Madrid</td>
        <td class="w-2/8"></td>
        <td class="w-3/8">Tel +(34) 913081393-Fax +(34) 913192445</td>
        <td class="w-3/8">www.ciadelamoda.com</td>
      </tr>
    </table>
  </div>-->
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosVendidoVsConfirmado } from "@/types/reports/pedidos/pedidosVendidoVsConfirmado/PedidosVendidoVsConfirmado";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";
export default defineComponent({
  name: "PedidosVendidoVsConfirmado",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<PedidosVendidoVsConfirmado> = ref({
      totalPrendasVendidas: "",
      totalImporteVendido: "",
      totalPrendasConfirmadas: "",
      porcTotalPrendasConfirmadas: "",
      totalImporteConfirmado: "",
      porcTotalImporteConfirmado: "",
      colecciones: []
    });
    const route = useRoute();
    const detail = ref(true);
    const isLoading = ref(true);
    detail.value = route.params.detail == "yes";
    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosVendidoVsConfirmadoDB(filter);
      report.value = response;
      isLoading.value = false;
    }
    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosVendidoVsConfirmadoDB(filter, detail.value? "yes": "no");
    }
    onMounted(getReport);
    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}
table table {
  /*text-align: left;*/
}
thead td {
  /*text-align: center;*/
}
/*.bloque {
  page-break-inside: avoid;
}*/
.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}
@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: landscape;
  }
  .header {
    visibility: visible;
  }
  .page-footer-space {
    height: 20px;
  }
  .page-header-space {
    height: 0px;
  }
}
</style>