<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td colspan="6" class="text-center">
            <h1>
              Listado de clientes con Observaciones
            </h1>
          </td>
        </tr>
        <tr>
          <td class="w-1/12 text-left">Codigo</td>
          <td class="w-3/12">Nombre</td>
          <td class="w-3/12">Domicilio de correspondencia</td>
          <td class="w-1/12">Teléfonos</td>
          <td class="w-212">Móvil/Fax</td>
          <td class="w-2/12">Persona de contacto</td>
        </tr>
        <tr>
          <td
            colspan="6"
            class="p-1 text-right border-t-2 border-gray-800 font-semibold"
          ></td>
        </tr>
      </thead>
      <tr
        v-for="provincia in report.provincias"
        :key="provincia.nombreProvincia"
      >
        <td colspan="6">
          <table>
            <tr>
              <td>
                <table class="border-2 border-gray-800 font-semibold">
                  <tr>
                    <td></td>
                    <td class="w-2/12">{{ provincia.nombreProvincia }}</td>
                    <td class="w-10/12 text-right"></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <table>
            <tr
              v-for="cliente in provincia.clientes"
              :key="cliente.codigoCliente"
            >
              <td colspan="7" class="bloque border-b-2">
                <table class="leading-1 print:leading-3">
                  <tr>
                    <td class="w-1/12 align-top">
                      <table>
                        <tr>
                          <td>{{ cliente.codigoCliente }}</td>
                        </tr>
                        <tr>
                          <td>{{ cliente.nifCliente }}</td>
                        </tr>
                      </table>
                    </td>
                    <td class="w-3/12">
                      <table>
                        <tr>
                          <td>{{ cliente.nombreCliente }}</td>
                        </tr>
                        <tr>
                          <td>{{ cliente.nombreTienda }}</td>
                        </tr>
                      </table>
                    </td>

                    <td class="w-3/12">
                      <table class="w-full">
                        <tr>
                          <td colspan="3">
                            {{ cliente.domicilioCorrespondenciaCliente }}
                          </td>
                        </tr>
                        <tr>
                          <td class="w-2/12">
                            {{ cliente.cpCorrespondenciaCliente }}
                          </td>
                          <td class="w-1/12 "></td>
                          <td class="w-9/12">
                            {{ cliente.poblacionCorrespondenciaCliente }}
                          </td>
                        </tr>
                      </table>
                    </td>

                    <td class="w-1/12">
                      <table>
                        <tr>
                          <td>{{ cliente.telefono1Cliente }}</td>
                        </tr>
                        <tr>
                          <td>{{ cliente.telefono2Cliente }}</td>
                        </tr>
                      </table>
                    </td>
                    <td class="w-2/12">
                      <table>
                        <tr>
                          <td class="w-1/2">{{ cliente.movilCliente }}</td>
                          <td class="w-1/2">{{ cliente.faxCliente }}</td>
                        </tr>
                        <tr>
                          <td colspan="2">{{ cliente.emailCliente }}</td>
                        </tr>
                      </table>
                    </td>
                    <td class="w-2/12 align-top">
                      {{ cliente.contactoCliente }}
                    </td>
                  </tr>
                  <tr v-if="observaciones">
                    <td colspan="1">Observaciones:</td>
                    <td colspan="5">{{ cliente.observacionesCliente }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <table class=" border-t-2 border-gray-800">
            <tr>
              <td colspan="1"></td>
              <td colspan="5" class="p-4">
                Total clientes en {{ provincia.nombreProvincia }} :{{
                  provincia.totalProvincia
                }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="p-4 border-2 border-gray-800">
        <td colspan="6" class="align-left">
          Total clientes: {{ report.totalClientes }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ClientesPorProvincia } from "@/types/reports/clientes/clientesPorProvincia/ClientesPorProvincia";
import { useRoute } from "vue-router";
import { ClienteFilter } from "@/types/clientes/ClienteView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "ClientesPorProvincia",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ClientesPorProvincia> = ref({
      totalClientes: "",
      provincias: []
    });

    const route = useRoute();

    const isLoading = ref(true);

    const observaciones = ref(true);

    observaciones.value = route.params.observaciones == "yes";

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportClientesPorProvincia(filter);
      report.value = response;
      isLoading.value = false;
    }

    onMounted(getReport);

    async function getExcel() {
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportClientesPorProvincia(filter, observaciones.value? "yes" : "no");
    }

    return {
      report,
      observaciones,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  /*text-align: left;*/
}

thead td {
  /*text-align: center;*/
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: landscape;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>
