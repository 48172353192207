
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosPrevisionComisiones } from "@/types/reports/pedidos/pedidosPrevisionComisiones/PedidosPrevisionComisiones";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "PedidosFacturasPendientes",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<PedidosPrevisionComisiones> = ref({
      totalImportePrevisto: "",
      totalImporteConfirmado: "",
      totalComisionPrevista: "",
      totalComisionConfirmada: "",
      colecciones: []
    });

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosPrevisionComisionesDB(filter);
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosPrevisionComisionesDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      isLoading,
      getExcel
    };
  }
});
