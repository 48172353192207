
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ClientesPorProvincia } from "@/types/reports/clientes/clientesPorProvincia/ClientesPorProvincia";
import { useRoute } from "vue-router";
import { ClienteFilter } from "@/types/clientes/ClienteView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "ClientesPorProvincia",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ClientesPorProvincia> = ref({
      totalClientes: "",
      provincias: []
    });

    const route = useRoute();

    const isLoading = ref(true);

    const observaciones = ref(true);

    observaciones.value = route.params.observaciones == "yes";

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportClientesPorProvincia(filter);
      report.value = response;
      isLoading.value = false;
    }

    onMounted(getReport);

    async function getExcel() {
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportClientesPorProvincia(filter, observaciones.value? "yes" : "no");
    }

    return {
      report,
      observaciones,
      isLoading,
      getExcel
    };
  }
});
