
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ClientesConColecciones } from "@/types/reports/clientes/clientesConColecciones/ClientesConColecciones";
import { ClienteFilter } from "@/types/clientes/ClienteView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "ClientesConColecciones",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ClientesConColecciones> = ref(
      {} as ClientesConColecciones
    );

    const route = useRoute();
    const param = route.params.years.toString();

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportClientesConColeccionesDB(filter, param);
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportClientesConColeccionesDB(filter, param);
    }

    onMounted(getReport);

    return {
      report,
      isLoading,
      getExcel
    };
  }
});
