
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosVendidoVsConfirmado } from "@/types/reports/pedidos/pedidosVendidoVsConfirmado/PedidosVendidoVsConfirmado";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";
export default defineComponent({
  name: "PedidosVendidoVsConfirmado",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<PedidosVendidoVsConfirmado> = ref({
      totalPrendasVendidas: "",
      totalImporteVendido: "",
      totalPrendasConfirmadas: "",
      porcTotalPrendasConfirmadas: "",
      totalImporteConfirmado: "",
      porcTotalImporteConfirmado: "",
      colecciones: []
    });
    const route = useRoute();
    const detail = ref(true);
    const isLoading = ref(true);
    detail.value = route.params.detail == "yes";
    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosVendidoVsConfirmadoDB(filter);
      report.value = response;
      isLoading.value = false;
    }
    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosVendidoVsConfirmadoDB(filter, detail.value? "yes": "no");
    }
    onMounted(getReport);
    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
