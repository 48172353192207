
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { PedidosPorColecciones } from "@/types/reports/pedidos/pedidosPorColecciones/PedidosPorColecciones";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage
  },
  setup() {
    const report: Ref<PedidosPorColecciones> = ref({
      numTotalPrendasConfirmadas: "",
      importeTotalPrendasConfirmadas: "",
      numTotalPrendasServidas: "",
      porcNumTotalPrendasServidas: "",
      importeTotalPrendasServidas: "",
      porcImporteTotalPrendasServidas: "",
      colecciones: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosPorColeccionesDB(filter);
      report.value = response;
      isLoading.value = false;
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading
    };
  }
});
