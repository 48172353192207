
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { AgendaCitasConColecciones } from "@/types/reports/agenda/agendaCitasConColecciones/AgendaCitasConColecciones";
import { CitaFilter } from "@/types/agenda/CitaView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import { useRoute } from "vue-router";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<AgendaCitasConColecciones> = ref({
      temporadas: []
    });

    const route = useRoute();
    const param = route.params.years.toString();

    const detail = ref(true);

    const isLoading = ref(true);

    detail.value = route.params.detail == "yes";
    //console.log(detail.value);
    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("agendaFilter");
      let filter = {} as CitaFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      //console.log('hola');
      const response = await api.getReportAgendaCitasConColeccionesDB(filter, param);
      report.value = response;
      isLoading.value = false;
      //console.log(report);
    }

    async function getExcel() {
      const filterString = localStorage.getItem("agendaFilter");
      let filter = {} as CitaFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      let detalle = "";
      if (detail.value) {detalle = "yes"} else {detalle = "no"};
      apiExcel.getReportAgendaCitasConColeccionesDB(filter, param, detalle);
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
