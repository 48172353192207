
import { defineComponent, onMounted, ref, Ref } from "vue";
import { useRoute } from "vue-router";

import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosBudget } from "@/types/reports/pedidos/pedidosBudget/PedidosBudget";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    excelExport
  },
  setup() {
    const report: Ref<PedidosBudget> = ref({
      cabecera: [],
      tiendas: []
    });

    const cols = ref(0);

    const isLoading = ref(true);

    const route = useRoute();
    const param = route.params.marca.toString();

    function columnas(): number {
      let col = 0;
      console.log(report.value.cabecera.length);

      for (let i = 0; i < report.value.cabecera.length; i++) {
        console.log("dentro del bucle");
        console.log(report.value.cabecera[i].colecciones.length);
        col += report.value.cabecera[i].colecciones.length;
      }
      return col * 4 + 3;
    }

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosBudgetDB(filter, param);

      report.value = response;
      cols.value = columnas();
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosBudgetDB(filter, param);
    }

    onMounted(getReport);

    return {
      report,
      cols,
      isLoading,
      getExcel
    };
  }
});
