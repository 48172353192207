<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td v-bind:colspan="cols" class="text-center">
            <h5 CLASS="text-xl">
              LISTADO DE BUDGET
            </h5>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            class="bg-blue-400 border-black border-t-2 border-black border-l-2 border-black border-r-2 text-center"
          ></td>
          <template
            v-for="temporada in report.cabecera"
            :key="temporada.descripcionTemporada"
          >
            <td class="w-2"></td>
            <td
              v-bind:colspan="temporada.colecciones.length * 4 - 1"
              class="border-black border text-center bg-blue-400"
            >
              {{ temporada.descripcionTemporada }}
            </td>
          </template>
        </tr>
        <tr>
          <td
            colspan="3"
            class="bg-blue-400 text-center border-black border-r-2 border-black border-l-2"
          ></td>
          <template
            v-for="temporada in report.cabecera"
            :key="temporada.descripcionTemporada"
          >
            <template
              v-for="coleccion in temporada.colecciones"
              :key="coleccion.descripcionColeccion"
            >
              <td colspan="1"></td>
              <td
                colspan="3"
                class="border-black border text-center bg-blue-200"
              >
                {{ coleccion.descripcionColeccion }}
              </td>
            </template>
          </template>
        </tr>
        <tr>
          <td
            class="border-black border-b-2 bg-blue-400 text-left border-black border-l-2"
          >
            Tienda
          </td>
          <td class="border-black border-b-2 bg-blue-400 text-left">
            Población
          </td>
          <td
            class="border-black border-b-2 bg-blue-400 text-right border-black border-r-2"
          >
            Media
          </td>
          <template
            v-for="temporada in report.cabecera"
            :key="temporada.descripcionTemporada"
          >
            <template
              v-for="coleccion in temporada.colecciones"
              :key="coleccion.descripcionColeccion"
            >
              <td colspan="1"></td>
              <td colspan="1" class="border-black border text-right">
                Prendas
              </td>
              <td colspan="1" class="border-black border text-right">
                Importe
              </td>
              <td colspan="1" class="border-black border text-right">
                Media
              </td>
            </template>
          </template>
        </tr>
      </thead>
      <tr v-for="tienda in report.tiendas" :key="tienda.nombreTienda">
        <td
          class="bg-blue-200 text-left border-black border-l-2 border-b border-black border-r"
        >
          {{ tienda.nombreTienda }}
        </td>
        <td class="bg-blue-200 text-left border-black border-b border-r-2">
          {{ tienda.poblacionTienda }}
        </td>
        <td class="bg-blue-200 text-right border-black border-b border-r-2">
          {{ tienda.mediaPedidos }}
        </td>
        <template
          v-for="coleccion in tienda.colecciones"
          :key="coleccion.prendas"
        >
          <td></td>
          <td class="text-right border-black border">
            {{ coleccion.prendas }}
          </td>
          <td class="text-right border-black border">
            {{ coleccion.importe }}
          </td>
          <td class="text-right border-black border">
            {{ coleccion.mediaPedidos }}
          </td>
        </template>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <template
          v-for="temporada in report.cabecera"
          :key="temporada.descripcionTemporada"
        >
          <template
            v-for="coleccion in temporada.colecciones"
            :key="coleccion.descripcionColeccion"
          >
          <td colspan="1"></td>
            <td colspan="1" class="border-black border text-right font-semibold">
              {{ coleccion.totalPrendas }}
            </td>
            <td colspan="1" class="border-black border text-right font-semibold">
              {{ coleccion.totalImporte }}
            </td>
            <td colspan="1" class="border-black border text-right font-semibold">
              {{ coleccion.totalMediaPedidos }}
            </td>
          </template>
        </template>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { useRoute } from "vue-router";

import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosBudget } from "@/types/reports/pedidos/pedidosBudget/PedidosBudget";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    excelExport
  },
  setup() {
    const report: Ref<PedidosBudget> = ref({
      cabecera: [],
      tiendas: []
    });

    const cols = ref(0);

    const isLoading = ref(true);

    const route = useRoute();
    const param = route.params.marca.toString();

    function columnas(): number {
      let col = 0;
      console.log(report.value.cabecera.length);

      for (let i = 0; i < report.value.cabecera.length; i++) {
        console.log("dentro del bucle");
        console.log(report.value.cabecera[i].colecciones.length);
        col += report.value.cabecera[i].colecciones.length;
      }
      return col * 4 + 3;
    }

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosBudgetDB(filter, param);

      report.value = response;
      cols.value = columnas();
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosBudgetDB(filter, param);
    }

    onMounted(getReport);

    return {
      report,
      cols,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  text-align: left;
}

/*td {
  border: 1px solid #ddd;
}*/

/*thead td {
  text-align: center;
}*/

.bloque {
  page-break-inside: avoid;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>
