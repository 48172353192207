
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ExpedicionesPrevisionComisiones } from "@/types/reports/expediciones/expedicionesPrevisionComisiones/ExpedicionesPrevisionComisiones";
import { ExpedicionFilter } from "@/types/expediciones/ExpedicionView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ExpedicionesPrevisionComisiones> = ref({
      totalGeneralImporteComisiones: "",
      fabricantes: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("expedicionFilter");
      let filter = {} as ExpedicionFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportExpedicionPrevisionComisionesDB(filter);
      report.value = response;
      //console.log(report);
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("expedicionFilter");
      let filter = {} as ExpedicionFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportExpedicionPrevisionComisionesDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
