<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <h1>
              Listado de previsión de comisiones
            </h1>
          </td>
        </tr>
        <tr>
          <td class="w-3/12">Colección</td>
          <td class="w-3/12">Cliente</td>
          <td class="w-1/12">Fecha expedición</td>
          <td class="w-1/12">Fecha vencimiento</td>
          <td class="w-1/12">Importe exp.</td>
          <td class="w-1/12">% comisión</td>
          <td class="w-1/12">Importe Comisión</td>
          <td class="w-1/12">Número Factura</td>
        </tr>
      </thead>
      <tr
        v-for="fabricante in report.fabricantes"
        :key="fabricante.nombreFbaricante"
      >
        <td colspan="8" class="bloque leading-none">
          <table class="border-2 border-gray-800 font-semibold">
            <tr>
              <td colspan="6" class="w-10/12">
                {{ fabricante.nombreFabricante }}
              </td>
              <td class="w-1/12 text-right">
                {{ fabricante.totalImporteComisiones }}
              </td>
              <td></td>
            </tr>
          </table>
          <table class="mb-5 leading-1 print:leading-3">
            <tr class="border-b-2 pt-2" v-for="expedicion in fabricante.expediciones" :key="expedicion.idExpedicion">
              <td class="w-3/12 p-1">
                {{ expedicion.descripcionColeccion }}
              </td>
              <td class="w-3/12 p-1 ">
                {{ expedicion.cliente }}
              </td>
              <td class="w-1/12 p-1">
                {{ expedicion.fechaExpedicion }}
              </td>
              <td class="w-1/12 p-1">
                {{ expedicion.fechaVencimiento }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.importeExpedicion }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.porcentajeComision }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.importeComision }}
              </td>
              <td class="w-1/12 p-1">
                {{ expedicion.numeroFactura }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="border-t border-gray-800">
        <td colspan="6" class="w-11/12 p-1">
          TOTAL GENERAL
        </td>
        <td class="w-1/12 p-1 font-semibold text-right">
          {{ report.totalGeneralImporteComisiones }}
        </td>
        <td></td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ExpedicionesPrevisionComisiones } from "@/types/reports/expediciones/expedicionesPrevisionComisiones/ExpedicionesPrevisionComisiones";
import { ExpedicionFilter } from "@/types/expediciones/ExpedicionView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ExpedicionesPrevisionComisiones> = ref({
      totalGeneralImporteComisiones: "",
      fabricantes: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("expedicionFilter");
      let filter = {} as ExpedicionFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportExpedicionPrevisionComisionesDB(filter);
      report.value = response;
      //console.log(report);
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("expedicionFilter");
      let filter = {} as ExpedicionFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportExpedicionPrevisionComisionesDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  text-align: left;
}

thead td {
  text-align: center;
}

.bloque {
  page-break-inside: avoid;
}

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: portait;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>