<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <h1>
              Listado facturas
            </h1>
          </td>
        </tr>
        <tr>
          <td class="w-2/12">Nº de factura</td>
          <td class="w-2/12">Tipo</td>
          <td class="w-1/12">Fecha</td>
          <td class="w-5/12">Receptor</td>
          <td class="w-2/12">Importe</td>
        </tr>
      </thead>
      <tr v-for="factura in report.facturas" :key="factura.idFactura">
        <td class="w-2/12 p-1">
          {{ factura.numeroFactura }}
        </td>
        <td class="w-2/12 p-1">
          {{ factura.tipoFactura }}
        </td>
        <td class="w-1/12 p-1">
          {{ factura.fechaFactura }}
        </td>
        <td class="w-5/12 p-1">
          {{ factura.nombreReceptor }}
        </td>
        <td class="w-2/12 p-1 text-right">
          {{ factura.importe }}
        </td>
      </tr>
      <tr class="border-t border-gray-800">
        <td colspan="4" class="w-10/12 p-1">
          TOTAL GENERAL
        </td>
        <td class="w-1/12 p-1 font-semibold text-right">
          {{ report.totalGeneralImporte }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { FacturasFacturas } from "@/types/reports/facturas/facturasFacturas/FacturasFacturas";
import { FacturaFilter } from "@/types/facturas/FacturaView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<FacturasFacturas> = ref({
      totalGeneralImporte: "",
      facturas: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("facturaFilter");
      let filter = {} as FacturaFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      //console.log('hola');
      const response = await api.getReportFacturasFacturasDB(filter);
      report.value = response;
      //console.log(report);
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("facturaFilter");
      let filter = {} as FacturaFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportFacturasFacturasDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  text-align: left;
}

thead td {
  text-align: center;
}

.bloque {
  page-break-inside: avoid;
}

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: portait;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>