
import { defineComponent, onMounted, ref, Ref } from "vue";

import { api } from "@/services/reports/api";
import { FacturaPrint } from "@/types/reports/facturas/facturaPrint/FacturaPrint";
import { FacturaFilter } from "@/types/facturas/FacturaView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";

export default defineComponent({
  name: "FacturaPrint",
  components: {
    loading
  },
  setup() {
    const report: Ref<FacturaPrint> = ref({} as FacturaPrint);

    const isLoading = ref(true);

    const route = useRoute();
    const param = route.params.factura.toString();

    const observacionOk = function(texto: string) {
      return texto.replace(/(?:\r\n|\r|\n)/g, "<br>");
    };

    async function getReport() {
      isLoading.value = true;
      const filter = {} as FacturaFilter;

      if (param != null && param != undefined) {
        filter.id = parseInt(param);
        const response = await api.getReportFacturaPrintDB(filter);
        report.value = response;
      }
      isLoading.value = false;
      //window.print();
    }

    onMounted(getReport);

    window.onafterprint = function() {
      window.close();
    };

    return {
      report,
      isLoading,
      observacionOk
    };
  }
});
