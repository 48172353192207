
import { defineComponent } from "vue";

export default defineComponent({
  name: "excelExport",
  props: {},
  emits: ["exportexcel"],
  setup(props, { emit }) {
    function exportexcel() {
      emit("exportexcel");
    }
    return { exportexcel };
  }
});
