<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <h1>
              Listado de pedidos por colecciones
            </h1>
          </td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2">Pedidos (confirmados)</td>
          <td colspan="2">Servido (expedido)</td>
        </tr>
        <tr>
          <td class="w-1/12">N pedido</td>
          <td class="w-1/12">Fecha pedido</td>
          <td class="w-4/12">Cliente</td>
          <td class="w-1/12">N. prendas</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">N. Prendas</td>
          <td class="w-1/12">%</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">%</td>
        </tr>
      </thead>
      <tr
        v-for="coleccion in report.colecciones"
        :key="coleccion.descripcionColeccion"
      >
        <td colspan="9" class="bloque leading-none">
          <table :class="{ 'border-2 border-gray-800 font-semibold': detail }">
            <tr>
              <td class="w-6/12">
                {{ coleccion.descripcionColeccion }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.numTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.importeTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.numTotalPrendasServidas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.porcNumTotalPrendasServidas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.importeTotalPrendasServidas }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.porcImporteTotalPrendasServidas }}
              </td>
            </tr>
          </table>

          <table class="mb-2" v-if="detail">
            <tr class="border-b-2 pt-2" v-for="pedido in coleccion.pedidos" :key="pedido.idPedido">
              <td>
                <table>
                  <tr>
                    <td class="w-1/12 p-1">
                      {{ pedido.numPedido }}
                    </td>
                    <td class="w-1/12 p-1">
                      {{ pedido.fechaPedido }}
                    </td>
                    <td class="w-4/12 p-1">
                      {{ pedido.cliente }}
                    </td>
                    <td class="w-1/12 p-1 text-right">
                      {{ pedido.numPrendasConfirmadas }}
                    </td>
                    <td class="w-1/12 p-1 text-right">
                      {{ pedido.importePrendasConfirmadas }}
                    </td>
                    <td class="w-1/12 p-1 text-right">
                      {{ pedido.numPrendasServidas }}
                    </td>
                    <td class="w-1/12 p-1 text-right">
                      {{ pedido.porcNumPrendasServidas }}
                    </td>
                    <td class="w-1/12 p-1 text-right">
                      {{ pedido.importePrendasServidas }}
                    </td>
                    <td class="w-1/12 p-1 text-right">
                      {{ pedido.porcImportePrendasServidas }}
                    </td>
                  </tr>
                  <tr v-if="pedido.expediciones.length">
                    <td colspan="9">
                      <table class="w-full">
                        <thead>
                          <tr>
                            <td class="w-1/12 p-1">
                              Fecha expedición
                            </td>
                            <td class="w-1/12 p-1"></td>
                            <td class="w-4/12 p-1"></td>
                            <td class="w-1/12 p-1"></td>
                            <td class="w-1/12 p-1"></td>
                            <td class="w-1/12 p-1"></td>
                            <td class="w-1/12 p-1"></td>
                            <td class="w-1/12 p-1"></td>
                            <td class="w-1/12 p-1"></td>
                          </tr>
                        </thead>
                        <tr
                          v-for="expedicion in pedido.expediciones"
                          :key="expedicion.idExpedicion"
                        >
                          <td class="w-1/12 p-1 italic">
                            {{ expedicion.fechaExpedicion }}
                          </td>
                          <td colspan="4" class="w-7/12 p-1"></td>
                          <td class="w-1/12 p-1 text-right italic">
                            {{ expedicion.numPrendasServidas }}
                          </td>
                          <td class="w-1/12 p-1 text-right italic">
                            {{ expedicion.porcNumPrendasServidas }}
                          </td>
                          <td class="w-1/12 p-1 text-right italic">
                            {{ expedicion.importePrendasServidas }}
                          </td>
                          <td class="w-1/12 p-1 text-right italic">
                            {{ expedicion.porcImportePrendasServidas }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="w-8/12 p-1">
          TOTAL GENERAL
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.numTotalPrendasConfirmadas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.importeTotalPrendasConfirmadas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.numTotalPrendasServidas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.porcNumTotalPrendasServidas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.importeTotalPrendasServidas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.porcImporteTotalPrendasServidas }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { PedidosPorColecciones } from "@/types/reports/pedidos/pedidosPorColecciones/PedidosPorColecciones";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage
  },
  setup() {
    const report: Ref<PedidosPorColecciones> = ref({
      numTotalPrendasConfirmadas: "",
      importeTotalPrendasConfirmadas: "",
      numTotalPrendasServidas: "",
      porcNumTotalPrendasServidas: "",
      importeTotalPrendasServidas: "",
      porcImporteTotalPrendasServidas: "",
      colecciones: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosPorColeccionesDB(filter);
      report.value = response;
      isLoading.value = false;
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  /*text-align: left;*/
}

thead td {
  /*text-align: center;*/
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: landscape;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>