<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div class="vertical-orientation">
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      CIF B-96089560 Inscrita en el Registro Mercantil de Valencia-Tomo 3896
      Gral 1181 Secc Gral Libro Sociedades Folio 47 Hoja Nº V-16071 inscripción
      1ª.
    </p>
  </div>
  <div class="p-10 mx-5">
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead>
        <tr>
          <td class="text-3xl text-center" colspan="6">
            <h1>
              CIA DE LA MODA B.G., S.L.
            </h1>
          </td>
        </tr>
        <tr>
          <td class="text-lg text-center" colspan="6">
            <h2>
              CIF (ES) B-96089560
            </h2>
          </td>
        </tr>
      </thead>
      <tr>
        <td>
          <div class="mt-4"></div>
        </td>
      </tr>
      <tr v-for="factura in report.facturas" :key="factura.numeroFactura">
        <td>
          <table>
            <tr>
              <td class="w-5/12">
                <table>
                  <tr>
                    <td>Domicilio Social:</td>
                  </tr>
                  <tr>
                    <td class="pl-2">Paseo Recoletos, 5</td>
                  </tr>
                  <tr>
                    <td class="pl-2">Tel (34) 91 3081393*</td>
                  </tr>
                  <tr>
                    <td class="pl-2">Fax (34) 913192445</td>
                  </tr>
                  <tr>
                    <td class="pl-2">28804 Madrid</td>
                  </tr>
                </table>
              </td>
              <td class="w-1/12"></td>
              <td class="w-6/12-2">
                <table>
                  <tr
                    class="font-bold border-2 border-gray-900 mt-2 mb-2 pt-2 pb-2"
                  >
                    <td>
                      <div class="pt-4 pb-4 px-2 py-2">
                        Factura Nº {{ factura.numeroFactura }}
                      </div>
                    </td>
                    <td class="pt-4 pb-4 px-2 py-2 text-right">
                      Fecha: {{ factura.fechaFactura }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="pt-4 mb-4"></div>
                    </td>
                  </tr>
                  <tr
                    class="font-bold border-2 border-gray-900 pt-4 pb-4 px-2 py-2"
                  >
                    <td colspan="2">
                      <table>
                        <tr>
                          <td class="text-right">
                            <div class="px-2">{{ factura.nifReceptor }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="px-2">{{ factura.nombreReceptor }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="px-2">
                              {{ factura.domicilioReceptor }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="px-2">
                              {{ factura.cpReceptor }}
                              {{ report.poblacionReceptor }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td><div class="pt-4 mb-4"></div></td>
            </tr>
            <tr>
              <td colspan="4">
                <table>
                  <thead>
                    <td class="border-2 border-gray-900 w-6/9 p-1 text-left">
                      CONCEPTO
                    </td>
                    <td class="border-2 border-gray-900 w-1/9 text-center">
                      CANT.
                    </td>
                    <td class="border-2 border-gray-900 w-1/9 text-center">
                      PRECIO
                    </td>
                    <td class="border-2 border-gray-900 w-1/9 text-center">
                      IMPORTE
                    </td>
                  </thead>
                  <tr v-for="linea in factura.lineas" :key="linea.concepto">
                    <td class="w-6/9 p-1 text-left">
                      {{ linea.concepto }}
                    </td>
                    <td class="w-1/9 p-1 text-center">
                      {{ linea.cantidad }}
                    </td>
                    <td class="w-1/9 p-1 text-center">
                      {{ linea.precio }}
                    </td>
                    <td class="w-1/9 p-1 text-center">
                      {{ linea.importe }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <table>
                  <tr>
                    <td class="w-2/7 p-1 border-2 border-gray-900 text-center">
                      BASE IMPONIBLE: {{ factura.totalBI }}
                    </td>
                    <td class="w-1/7 p-1 border-2 border-gray-900 text-center">
                      DESCUENTO: {{ factura.totalDescuento }}
                    </td>
                    <td class="w-2/7 p-1 border-2 border-gray-900 text-center">
                      IVA: {{ factura.totalIVA }}
                    </td>
                    <td class="w-2/7 p-1 border-2 border-gray-900 text-center">
                      TOTAL FRA.: {{ factura.totalTotal }} €
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td><div class="py-4"></div></td>
            </tr>
            <tr>
              <td colspan="4"><div>Observaciones:</div></td>
            </tr>
            <tr>
              <td colspan="4">
                <div v-html="observacionOk(factura.observaciones)"></div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="footer">
                  <p>
                    In accordance with the provisions of Law 15/1999, of
                    December 13, Protection of Personal Data, consenting to your
                    data being stored in a file is the responsibility of the
                    company. The purpose of the file may it be: billing,
                    administrative and commercial activities derived from their
                    relationship with us.
                  </p>
                  <p>
                    The data requested is necessary, if it is not provided it is
                    not possible to provide the required service, so in this
                    sense, you expressly consent to the collection and
                    processing of the same for the above purpose.
                  </p>
                  <p>
                    Similarly, authorizing the communication and transfer of
                    personal data to public or private entities that require the
                    same for the purpose of meeting the obligations on a legal
                    basis (fiscal, labor or any other), performing communication
                    only for this purpose.
                  </p>
                  <p>
                    It is also herby informed about the possibility of
                    exercising ones rights of access, rectification,
                    cancellation and opposition to the CIA DE LA MODA BG, S.L. ,
                    PUEBLA DE FARNALS 20, 46021, VALENCIA or by sending a
                    message to the email address ciadelamoda@ciadelamoda.com
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";

import { api } from "@/services/reports/api";
import { FacturaPrint } from "@/types/reports/facturas/facturaPrint/FacturaPrint";
import { FacturaFilter } from "@/types/facturas/FacturaView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";

export default defineComponent({
  name: "FacturaPrint",
  components: {
    loading
  },
  setup() {
    const report: Ref<FacturaPrint> = ref({} as FacturaPrint);

    const isLoading = ref(true);

    const route = useRoute();
    const param = route.params.factura.toString();

    const observacionOk = function(texto: string) {
      return texto.replace(/(?:\r\n|\r|\n)/g, "<br>");
    };

    async function getReport() {
      isLoading.value = true;
      const filter = {} as FacturaFilter;

      if (param != null && param != undefined) {
        filter.id = parseInt(param);
        const response = await api.getReportFacturaPrintDB(filter);
        report.value = response;
      }
      isLoading.value = false;
      //window.print();
    }

    onMounted(getReport);

    window.onafterprint = function() {
      window.close();
    };

    return {
      report,
      isLoading,
      observacionOk
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  text-align: left;
  font-size: 10px;
}

.bloque {
  page-break-inside: avoid;
}

.footer {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  bottom: 0;
}
.vertical-orientation {
  float: left;
  transform: rotate(90deg);
  margin-left: 10px;
  font-size: 9px;
  transform-origin: left top;
  /*
                 transform-origin: left top 0;
                margin-left: 50px;
                padding: 10px;
                background-color: rgba(37, 34, 34, 0.3);
                opacity: 0.9;
                font-size: 1.8em;
                color: #FFF;
                text-transform: uppercase;*/
}

@media print {
  @page {
    orientation: portrait;
  }
}
</style>
