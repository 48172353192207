<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-center">
            <h1>
              Listado de clientes con e-mail
            </h1>
          </td>
        </tr>
        <tr>
          <td class="w-7/12 text-left">Nombre</td>
          <td class="w-5/12">E-mail</td>
        </tr>
        <tr>
          <td colspan="6" class="p-1 text-right border-t-2 border-black"></td>
        </tr>
      </thead>
      <tr v-for="cliente in report.clientes" :key="cliente.codigoCliente">
        <td colspan="6">
          <table class="leading-1 print:leading-3">
            <tr>
              <td class="w-7/12 align-top">
                {{ cliente.nombreCliente }}
              </td>
              <td class="w-5/12">
                {{ cliente.emailCliente }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
       <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ClientesEmail } from "@/types/reports/clientes/clientesEmail/ClientesEmail";
import { ClienteFilter } from "@/types/clientes/ClienteView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "ClientesEmail",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ClientesEmail> = ref({
      clientes: []
    });

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportClientesEmailDB(filter);
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportClientesEmailDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  /*text-align: left;*/
}

thead td {
  /*text-align: center;*/
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: portait;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>
