<template>
  <div class="footerPage">
    <div class="flex justify-between border-t pt-2">
      <p class="font-semibold">CIA DE LA MODA, B.G., S.L.</p>
      <p>Tel +(34) 913081393</p>
      <p>www.ciadelamoda.com</p>
      <p>info@ciadelamoda.com</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "footerPage"
});
</script>
<style scoped>
.footerPage {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
  visibility: hidden;
}

@media print {
  .footerPage {
    visibility: visible;
  }
}
</style>
