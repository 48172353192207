
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosConfirmadosVsServidos } from "@/types/reports/pedidos/pedidosConfirmadosVsServidos/PedidosConfirmadosVsServidos";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<PedidosConfirmadosVsServidos> = ref({
      numTotalPrendasConfirmadas: "",
      importeTotalPrendasConfirmadas: "",
      porcNumTotalPrendasServidas: "",
      numTotalPrendasServidas: "",
      importeTotalPrendasServidas: "",
      porcImporteTotalPrendasServidas: "",
      resumenes: []
    });
    const route = useRoute();

    const detail = ref(true);

    const isLoading = ref(true);

    detail.value = route.params.detail == "yes";

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosConfirmadosVsServidosDB(
        filter
      );
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosConfirmadosVsServidosDB(filter, detail.value? "yes": "no");
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
