<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <h1>
              Listado de pedidos (confirmados-servido) - detallado
            </h1>
          </td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2">Pedido (confirmado)</td>
          <td colspan="4">Servido (expedido)</td>
        </tr>
        <tr class="border-b">
          <td class="w-1/12">N.pedido</td>
          <td class="w-1/12">F.pedido</td>
          <td class="w-4/12">Cliente</td>
          <td class="w-1/12">N. prendas</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">N. Prendas</td>
          <td class="w-1/12">%</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">%</td>
        </tr>
      </thead>
      <tr v-for="resumen in report.resumenes" :key="resumen.media">
        <td colspan="9" class="bloque leading-none">
          <table :class="{ 'border-2 border-gray-800 font-semibold': detail }">
            <tr>
              <td class="w-6/12">
                {{ resumen.descripcionColeccion }} ({{ resumen.numPedidos }}) -
                Media:
                {{ resumen.media }}
              </td>
              <td class="w-1/12 text-right">
                {{ resumen.numTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ resumen.importeTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ resumen.numTotalPrendasServidas }} 
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ resumen.porcNumTotalPrendasServidas }}%
              </td>
              <td class="w-1/12 text-right">
                {{ resumen.importeTotalPrendasServidas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ resumen.porcImporteTotalPrendasServidas }}%
              </td>
            </tr>
          </table>

          <table class="mb-5 leading-1 print:leading-3" v-if="detail">
            <tr v-for="pedido in resumen.pedidos" :key="pedido.n_pedido">
              <td class="w-1/12 p-1">
                {{ pedido.numPedido }}
              </td>
              <td class="w-1/12 p-1">
                {{ pedido.fechaPedido }}
              </td>
              <td class="w-4/12 p-1">
                {{ pedido.cliente }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.numPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.importePrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.numPrendasServidas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.porcNumPrendasServidas }}%
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.importePrendasServidas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.porcImportePrendasServidas }}%
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="9">
          <table :class="{ 'border-2 border-gray-800 font-semibold': detail }">
            <tr>
              <td class="w-6/12 p-1">
                TOTAL GENERAL
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.numTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.importeTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.numTotalPrendasServidas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.porcNumTotalPrendasServidas }}%
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.importeTotalPrendasServidas }}
              </td>
              <td class="w-1/12 p-1 text-right border-t-2 border-black">
                {{ report.porcImporteTotalPrendasServidas }}%
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <p></p>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosConfirmadosVsServidos } from "@/types/reports/pedidos/pedidosConfirmadosVsServidos/PedidosConfirmadosVsServidos";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<PedidosConfirmadosVsServidos> = ref({
      numTotalPrendasConfirmadas: "",
      importeTotalPrendasConfirmadas: "",
      porcNumTotalPrendasServidas: "",
      numTotalPrendasServidas: "",
      importeTotalPrendasServidas: "",
      porcImporteTotalPrendasServidas: "",
      resumenes: []
    });
    const route = useRoute();

    const detail = ref(true);

    const isLoading = ref(true);

    detail.value = route.params.detail == "yes";

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosConfirmadosVsServidosDB(
        filter
      );
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosConfirmadosVsServidosDB(filter, detail.value? "yes": "no");
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  text-align: left;
}

thead td {
  text-align: center;
}

.bloque {
  page-break-inside: avoid;
}

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: landscape;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 30px;
  }

}
</style>
