<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <h1>
              Listado de previsión de comisiones
            </h1>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td colspan="2">Vendido</td>
          <td colspan="1"></td>
          <td colspan="2">Confirmado</td>
        </tr>
        <tr>
          <td class="w-5/12">Colección</td>
          <td class="w-2/12"></td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">Comsión prev.</td>
          <td class="w-1/12"></td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">Comsión prev.</td>
        </tr>
      </thead>
      <tr
        v-for="coleccion in report.colecciones"
        :key="coleccion.descripcionColeccion"
        class="leading-1 print:leading-3"
      >
        <td colspan="7">
          <table class="mb-2">
            <tr>
              <td class="w-5/12">
                {{ coleccion.descripcionColeccion }}
              </td>
              <td></td>
              <td class="w-1/12 text-right">
                {{ coleccion.importePrevisto }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.comisionPrevista }}
              </td>
              <td></td>
              <td class="w-1/12 text-right">
                {{ coleccion.importeConfirmado }}
              </td>
              <td class="w-1/12 text-right">
                {{ coleccion.comisionConfirmada }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="w-8/12 p-1">
          TOTAL GENERAL
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.totalImportePrevisto }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.totalComisionPrevista }}
        </td>
        <td></td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.totalImporteConfirmado }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.totalComisionConfirmada }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { PedidosPrevisionComisiones } from "@/types/reports/pedidos/pedidosPrevisionComisiones/PedidosPrevisionComisiones";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "PedidosFacturasPendientes",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<PedidosPrevisionComisiones> = ref({
      totalImportePrevisto: "",
      totalImporteConfirmado: "",
      totalComisionPrevista: "",
      totalComisionConfirmada: "",
      colecciones: []
    });

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosPrevisionComisionesDB(filter);
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportPedidosPrevisionComisionesDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  /*text-align: left;*/
}

thead td {
  /*text-align: center;*/
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: portait;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>
