import data2 from "./dataFacturasPorTemporada.json";
import data4 from "./dataPedidosFacturasPendientes.json";
import axios from "../apiService";

import { PedidosConfirmadosVsServidos } from "@/types/reports/pedidos/pedidosConfirmadosVsServidos/PedidosConfirmadosVsServidos";
import { FacturasPorTemporada } from "@/types/reports/facturas/facturasPorTemporada/FacturasPorTemporada";
import { ClientesConColecciones } from "@/types/reports/clientes/clientesConColecciones/ClientesConColecciones";
import { PedidosFacturasPendientes } from "@/types/reports/pedidos/pedidosFacturasPendientes/PedidosfacturasPendientes";
import { PedidosVendidoVsConfirmado } from "@/types/reports/pedidos/pedidosVendidoVsConfirmado/PedidosVendidoVsConfirmado";
import { ClientesPorProvincia } from "@/types/reports/clientes/clientesPorProvincia/ClientesPorProvincia";
import { ClientesPorMarca } from "@/types/reports/clientes/clientesPorMarca/ClientesPorMarca";
import { ClientesEmail } from "@/types/reports/clientes/clientesEmail/ClientesEmail";
import { PedidosPrevisionComisiones } from "@/types/reports/pedidos/pedidosPrevisionComisiones/PedidosPrevisionComisiones";
import { PedidosPorColecciones } from "@/types/reports/pedidos/pedidosPorColecciones/PedidosPorColecciones";
import { PedidosBudget } from "@/types/reports/pedidos/pedidosBudget/PedidosBudget";
import { FacturaPrint } from "@/types/reports/facturas/facturaPrint/FacturaPrint";
import { ExpedicionesPrevisionComisiones } from "@/types/reports/expediciones/expedicionesPrevisionComisiones/ExpedicionesPrevisionComisiones";
import { ExpedicionesEstadoComisiones } from "@/types/reports/expediciones/expedicionesEstadoComisiones/ExpedicionesEstadoComisiones";
import { ComisionesComisiones } from "@/types/reports/comisiones/comisionesComisiones/ComisionesComisiones";

import { PedidoFilter } from "@/types/pedidos/PedidoView";
import { ClienteFilter } from "@/types/clientes/ClienteView";
import { FacturaFilter } from "@/types/facturas/FacturaView";
import { ExpedicionFilter } from "@/types/expediciones/ExpedicionView";
import { ComisionFilter } from "@/types/comisiones/ComisionView";
import { FacturasPorReceptor } from "@/types/reports/facturas/facturasPorReceptor/FacturasPorReceptor";
import { FacturasFacturas } from "@/types/reports/facturas/facturasFacturas/FacturasFacturas";
import { CitaFilter } from "@/types/agenda/CitaView";
import { FabricanteFilter } from "@/types/fabricantes/FabricanteView";
import { AgendaCitasConColecciones } from "@/types/reports/agenda/agendaCitasConColecciones/AgendaCitasConColecciones";
import { FabricantesFabricantes } from "@/types/reports/fabricantes/fabricantesFabricantes/FabricantesFabricantes";


export const apiExcel = {
  //API report pedidos
  //********************************
  getReportPedidosConfirmadosVsServidosDB(
    filter: PedidoFilter,
    detalle: string
  ): Promise<PedidosConfirmadosVsServidos> {
    const filterParams = {
      filter: filter,
      parameters: [detalle]
    };
    return new Promise((resolve, reject) => {
      const url = "PedidosConfirmadosVsServidos/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "PedidosConfirmadosVsServido.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportPedidosFacturasPendientes(
    filter: PedidoFilter
  ): Promise<PedidosFacturasPendientes> {
    console.log(filter);
    return new Promise(resolve => {
      resolve(data4);
    });
  },
  getReportPedidosVendidoVsConfirmadoDB(
    filter: PedidoFilter,
    detalle: string
  ): Promise<PedidosVendidoVsConfirmado> {
    const filterParams = {
      filter: filter,
      parameters: [detalle]
    };
    return new Promise((resolve, reject) => {
      const url = "PedidosVendidoVsConfirmado/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "PedidosVendidoVsConfirmado.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportPedidosPrevisionComisionesDB(
    filter: PedidoFilter
  ): Promise<PedidosPrevisionComisiones> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "PedidosPrevisionComisiones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "PedidosPrevisionComisiones.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportPedidosPorColeccionesDB(
    filter: PedidoFilter
  ): Promise<PedidosPorColecciones> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "PedidosPorColecciones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "PedidosPorColecciones.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportPedidosBudgetDB(
    filter: PedidoFilter,
    marca: string
  ): Promise<PedidosBudget> {
    const filterParams = {
      filter: filter,
      parameters: ["2", marca]
    };
    return new Promise((resolve, reject) => {
      const url = "PedidosBudget/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "PedidosBudget.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  // API report facturas
  //********************************
  getReportFacturasPorTemporada(): Promise<FacturasPorTemporada> {
    return new Promise(resolve => {
      resolve(data2);
    });
  },
  //API report clientes
  //********************************
  getReportClientesConColeccionesDB(
    filter: ClienteFilter,
    years: string
  ): Promise<ClientesConColecciones> {
    const filterParams = {
      filter: filter,
      parameters: [years]
    };
    return new Promise((resolve, reject) => {
      const url = "ClientesConColecciones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ClientesConColecciones.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportClientesPorProvincia(
    filter: ClienteFilter,
    observaciones: string
  ): Promise<ClientesPorProvincia> {
    const filterParams = {
      filter: filter,
      parameters: [observaciones]
    };
    return new Promise((resolve, reject) => {
      const url = "ClientesPorProvincia/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ClientesPorProvincia.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportClientesPorMarcaDB(
    filter: ClienteFilter
  ): Promise<ClientesPorMarca> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "ClientesPorMarca/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ClientesPorMarca.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportClientesEmailDB(filter: ClienteFilter): Promise<ClientesEmail> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "ClientesEmail/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ClientesEmail.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportFacturaPrintDB(filter: FacturaFilter): Promise<FacturaPrint> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "FacturaPrint/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "FacturaPrint.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportExpedicionPrevisionComisionesDB(
    filter: ExpedicionFilter
  ): Promise<ExpedicionesPrevisionComisiones> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "ExpedicionesPrevisionComisiones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ExpedicionesPrevisionComisiones.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportExpedicionEstadoComisionesDB(
    filter: ExpedicionFilter
  ): Promise<ExpedicionesEstadoComisiones> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "ExpedicionesEstadoComisiones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ExpedicionesEstadoComisiones.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportComisionesComisionesDB(
    filter: ComisionFilter
  ): Promise<ComisionesComisiones> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "ComisionesComisiones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Comisiones.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportFacturasPorReceptorDB(
    filter: FacturaFilter
  ): Promise<FacturasPorReceptor> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "FacturasPorReceptor/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "FacturasPorReceptor.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportFacturasFacturasDB(
    filter: FacturaFilter
  ): Promise<FacturasFacturas> {
    const filterParams = {
      filter: filter,
      parameters: []
    };
    return new Promise((resolve, reject) => {
      const url = "FacturasFacturas/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Facturas.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportAgendaCitasConColeccionesDB(
    filter: CitaFilter,
    years: string,
    detail: string
  ): Promise<AgendaCitasConColecciones> {
    const filterParams = {
      filter: filter,
      parameters: [years, detail],
    };
    return new Promise((resolve, reject) => {
      const url = "AgendaCitasConColecciones/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "CitasAgenda.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  },
  getReportFabricantesFabricantesDB(
    filter: FabricanteFilter
  ): Promise<FabricantesFabricantes> {
    const filterParams = {
      filter: filter,
      parameters: [],
    };
    return new Promise((resolve, reject) => {
      const url = "FabricantesFabricantes/excel";
      axios
      .post(url, filterParams, { responseType: "blob" })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ListadoFabricantes.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(response => {
        reject(response.status);
      });
    });
  }
};
