<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td colspan="7">
            <h1>
              Listado de pedidos por Clientes con facturas pendientes
            </h1>
          </td>
        </tr>
        <tr>
          <td colspan="3"></td>
          <td colspan="2">Pedidos (confirmados)</td>
          <td colspan="2">Servido (expedido)</td>
        </tr>
        <tr>
          <td class="w-1/12">N pedido</td>
          <td class="w-1/12">Fecha pedido</td>
          <td class="w-6/12">Colección</td>
          <td class="w-1/12">N. prendas</td>
          <td class="w-1/12">Importe</td>
          <td class="w-1/12">N. Prendas</td>
          <td class="w-1/12">Importe</td>
        </tr>
      </thead>
      <tr v-for="cliente in report.clientes" :key="cliente.nombreCliente">
        <td colspan="7">
          <table :class="{ 'border-2 border-gray-800 font-semibold': detail }">
            <tr>
              <td class="w-8/12">
                {{ cliente.nombreCliente }} / {{ cliente.nombreTienda }} ({{
                  cliente.numPedidos
                }})
              </td>
              <td class="w-1/12 text-right">
                {{ cliente.numTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ cliente.importeTotalPrendasConfirmadas }}
              </td>
              <td class="w-1/12 text-right">
                {{ cliente.numTotalPrendasServidas }}
              </td>
              <td class="w-1/12 text-right">
                {{ cliente.importeTotalPrendasServidas }}
              </td>
            </tr>
          </table>

          <table class="mb-5" v-if="detail">
            <tr v-for="pedido in cliente.pedidos" :key="pedido.numeroPedido">
              <td class="w-1/12 p-1">
                {{ pedido.numeroPedido }}
              </td>
              <td class="w-1/12 p-1">
                {{ pedido.fechaPedido }}
              </td>
              <td class="w-6/12 p-1">
                {{ pedido.coleccion }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.numPrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.importePrendasConfirmadas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.numPrendasServidas }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ pedido.importePrendasServidas }}
              </td>
            </tr>
          </table>

          <table class="mb-5" v-if="detail">
            <thead class="font-black">
              <tr>
                <td colspan="10">
                  <h1>
                    FACTURAS NO PAGADAS
                  </h1>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="border-black border-b-2">
                  Datos de la Expedicion
                </td>
                <td colspan="1"></td>
                <td colspan="3" class="border-black border-b-2">
                  Datos Vencimientos
                </td>
              </tr>

              <tr>
                <td class="w-1/12">N pedido</td>
                <td class="w-3/12">Colección</td>
                <td class="w-1/12">N. factura</td>
                <td class="w-1/12">Fecha</td>
                <td class="w-1/12">Importe</td>
                <td class="w-1/12">Prendas</td>
                <td class="w-1/12"></td>
                <td class="w-1/12">Fecha</td>
                <td class="w-1/12">Importe</td>
                <td class="w-1/12">Pagado</td>
              </tr>
            </thead>
            <tr
              v-for="expedicion in cliente.expediciones"
              :key="expedicion.idExpedicion"
            >
              <td class="w-1/12 p-1">
                {{ expedicion.numeroPedido }}
              </td>
              <td class="w-1/12 p-1">
                {{ expedicion.coleccion }}
              </td>
              <td class="w-1/12 p-1">
                {{ expedicion.numeroFactura }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.fechaExpedicion }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.importeExpedicion }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.numeroPrendasExpedicion }}
              </td>
              <td class="w-1/12 p-1 text-right"></td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.fechaVencimiento }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.importeVencimiento }}
              </td>
              <td class="w-1/12 p-1 text-right">
                {{ expedicion.pagadoVencimiento }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="w-8/12 p-1">
          TOTAL GENERAL
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.numTotalPrendasConfirmadas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.importeTotalPrendasConfirmadas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.numTotalPrendasServidas }}
        </td>
        <td class="w-1/12 p-1 text-right border-t-2 border-black">
          {{ report.importeTotalPrendasServidas }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { PedidosFacturasPendientes } from "@/types/reports/pedidos/pedidosFacturasPendientes/PedidosfacturasPendientes";
import { PedidoFilter } from "@/types/pedidos/PedidoView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";

export default defineComponent({
  name: "PedidosFacturasPendientes",
  components: {
    loading,
    footerPage
  },
  setup() {
    const report: Ref<PedidosFacturasPendientes> = ref({
      numTotalPrendasConfirmadas: "",
      importeTotalPrendasConfirmadas: "",
      numTotalPrendasServidas: "",
      importeTotalPrendasServidas: "",
      clientes: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("pedidoFilter");
      let filter = {} as PedidoFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportPedidosFacturasPendientes(filter);
      report.value = response;
      isLoading.value = false;
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  /*text-align: left;*/
}

thead td {
  /*text-align: center;*/
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: landscape;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>
