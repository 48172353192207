<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <h1>
              Listado de comisiones
            </h1>
          </td>
        </tr>
        <tr>
          <td class="w-5/12">Fabricante</td>
          <td class="w-1/12">Año</td>
          <td class="w-3/12">Temporada</td>
          <td class="w-1/12">Fecha</td>
          <td class="w-2/12">Comisión</td>
        </tr>
      </thead>
      <tr
        v-for="fabricante in report.fabricantes"
        :key="fabricante.nombreFabricante"
      >
        <td colspan="5" class="bloque leading-none">
          <table class="border-2 border-gray-800 font-semibold">
            <tr>
              <td colspan="4" class="w-10/12">
                {{ fabricante.nombreFabricante }}
              </td>
              <td class="w-2/12 text-right">
                {{ fabricante.totalFabricanteImporteComision }}
              </td>
            </tr>
          </table>
          <table class="mb-2 leading-1 print:leading-3">
            <tr class="border-b-2 pt-2" v-for="anyo in fabricante.anyos" :key="anyo.anyo">
               <td colspan="5" class="bloque leading-none">
                 <table class="border-2 border-gray-800 font-semibold">
                  <tr>
                    <td colspan="4"  class="w-10/12 p-1">
                      {{ fabricante.nombreFabricante }} - {{ anyo.anyo }}
                    </td>
                    <td class="w-2/12 p-1 text-right">
                      {{ anyo.totalAnyoImporteComision }}
                    </td>
                  </tr>
                </table>
                <table class="mb-2 leading-1 print:leading-3">
                  <tr class="border-b-2 pt-2" v-for="tipoTemporada in anyo.tiposTemporadas" :key="tipoTemporada.idTipoTemporada">
                    <td colspan="5" class="bloque leading-none">
                      <table class="border-2 border-gray-800 font-semibold">
                        <tr>
                          <td colspan="4" class="w-10/12 p-1">
                            {{ fabricante.nombreFabricante }} - {{ anyo.anyo }} {{ tipoTemporada.tipoTemporada }}
                          </td>
                          <td class="w-2/12 p-1 text-right">
                            {{ tipoTemporada.totalTipoTemporadaImporteComision }}
                          </td>
                        </tr>
                      </table>
                      <table class="mb-2 leading-1 print:leading-3">
                        <tr class="border-b-2 pt-2" v-for="comision in tipoTemporada.comisiones" :key="comision.fechaComision">
                          <td class="w-5/12 p-1">
                            {{ fabricante.nombreFabricante }}
                          </td>
                          <td class="w-1/12 p-1 text-right">
                            {{ anyo.anyo }}
                          </td>
                          <td class="w-3/12 p-1 text-right">
                            {{ tipoTemporada.tipoTemporada }}
                          </td>
                          <td class="w-1/12 p-1 text-right">
                            {{ comision.fechaComision }}
                          </td>
                          <td class="w-2/12 p-1 text-right">
                            {{ comision.importeComision }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="border-t border-gray-800">
        <td colspan="4" class="w-10/12 p-1">
          TOTAL GENERAL
        </td>
        <td class="w-1/12 p-1 font-semibold text-right">
          {{ report.totalGeneralImporteComision }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ComisionesComisiones } from "@/types/reports/comisiones/comisionesComisiones/ComisionesComisiones";
import { ComisionFilter } from "@/types/comisiones/ComisionView";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "Listado1",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ComisionesComisiones> = ref({
      totalGeneralImporteComision: "",
      fabricantes: []
    });

    const detail = ref(true);

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("comisionFilter");
      let filter = {} as ComisionFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      //console.log('hola');
      const response = await api.getReportComisionesComisionesDB(filter);
      report.value = response;
      //console.log(report);
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("comisionFilter");
      let filter = {} as ComisionFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportComisionesComisionesDB(filter);
    }

    onMounted(getReport);

    return {
      report,
      detail,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  text-align: left;
}

thead td {
  text-align: center;
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: portait;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>