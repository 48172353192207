<template>
  <div v-if="isLoading">
    <loading />
  </div>
  <div v-else class="p-10">
    <excelExport @exportexcel="getExcel()" />
    <table class="font-thin text-gray-700 text-sm table-auto">
      <thead class="font-black text-left">
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-center">
            <h1>
              Listado de clientes con colecciones pedidas
            </h1>
          </td>
        </tr>
        <tr>
          <td class="w-1/12 text-left">Codigo</td>
          <td class="w-3/12">Nombre</td>
          <td class="w-4/12">Domicilio de correspondencia</td>
          <td class="w-1/12">Teléfonos</td>
          <td class="w-1/12">Móvil/Fax</td>
          <td class="w-2/12">Persona de contacto</td>
        </tr>
        <tr>
          <td colspan="6" class=" text-right border-t-2 border-black"></td>
        </tr>
      </thead>
      <tr v-for="cliente in report.clientes" :key="cliente.codigoCliente">
        <td class="leading-none" colspan="6">
          <table class="leading-1 print:leading-3">
            <tr>
              <td class="w-1/12 align-top">
                {{ cliente.codigoCliente }}
              </td>
              <td class="w-3/12 align-top">
                <table>
                  <tr>
                    <td>{{ cliente.nombreCliente }}</td>
                  </tr>
                  <tr>
                    <td>{{ cliente.nombreTienda }}</td>
                  </tr>
                </table>
              </td>

              <td class="w-4/12 align-top">
                <table class="w-full">
                  <tr>
                    <td colspan="3">
                      {{ cliente.domicilioCorrespondenciaCliente }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-2/12">
                      {{ cliente.cpCorrespondenciaCliente }}
                    </td>
                    <td class="w-1/12 "></td>
                    <td class="w-9/12">
                      {{ cliente.poblacionCorrespondenciaCliente }}
                    </td>
                  </tr>
                </table>
              </td>

              <td class="w-1/12 align-top">
                <table>
                  <tr>
                    <td>{{ cliente.telefono1Cliente }}</td>
                  </tr>
                  <tr>
                    <td>{{ cliente.telefono2Cliente }}</td>
                  </tr>
                </table>
              </td>
              <td class="w-1/12 align-top">
                <table>
                  <tr>
                    <td>{{ cliente.movilCliente }}</td>
                  </tr>
                  <tr>
                    <td>{{ cliente.faxCliente }}</td>
                  </tr>
                </table>
              </td>

              <td class="w-2/12 align-top">{{ cliente.contactoCliente }}</td>
            </tr>
          </table>

          <table>
            <tr>
              <td class="w-1/12 align-top font-black italic">
                Colecciones:
              </td>
              <td class="w-11/12 leading-none">
                <table>
                  <tr
                    v-for="coleccion in cliente.colecciones"
                    :key="coleccion.nombreColeccion"
                  >
                    <td>
                      {{ coleccion.nombreColeccion }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td colspan="6" class="text-right border-t-2 border-black"></td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="p-1">
          Total clientes: {{ report.totalClientes }}
        </td>
      </tr>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
    <basic-input />
  </div>
  <footerPage></footerPage>
  <div class="header p-10">
    <table>
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref } from "vue";
import { api } from "@/services/reports/api";
import { apiExcel } from "@/services/reports/apiExcel";
import { ClientesConColecciones } from "@/types/reports/clientes/clientesConColecciones/ClientesConColecciones";
import { ClienteFilter } from "@/types/clientes/ClienteView";
import { useRoute } from "vue-router";
import loading from "@/components/loading.vue";
import footerPage from "@/components/footerPage.vue";
import excelExport from "@/components/excelExport.vue";

export default defineComponent({
  name: "ClientesConColecciones",
  components: {
    loading,
    footerPage,
    excelExport
  },
  setup() {
    const report: Ref<ClientesConColecciones> = ref(
      {} as ClientesConColecciones
    );

    const route = useRoute();
    const param = route.params.years.toString();

    const isLoading = ref(true);

    async function getReport() {
      isLoading.value = true;
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      const response = await api.getReportClientesConColeccionesDB(filter, param);
      report.value = response;
      isLoading.value = false;
    }

    async function getExcel() {
      const filterString = localStorage.getItem("clienteFilter");
      let filter = {} as ClienteFilter;
      if (filterString != undefined) filter = JSON.parse(filterString);
      apiExcel.getReportClientesConColeccionesDB(filter, param);
    }

    onMounted(getReport);

    return {
      report,
      isLoading,
      getExcel
    };
  }
});
</script>
<style scoped>
table {
  width: 100%;
}

table table {
  /*text-align: left;*/
}

thead td {
  /*text-align: center;*/
}

/*.bloque {
  page-break-inside: avoid;
}*/

.header {
  font-size: 9px;
  line-height: 10px;
  position: fixed;
  top: 0;
  visibility: hidden;
  counter-increment: page;
}

@media print {
  table {
    font-size: 10px;
  }
  td {
    line-height: 0.75rem;
  }
  @page {
    size: landscape;
  }

  .header {
    visibility: visible;
  }

  .page-footer-space {
    height: 20px;
  }

  .page-header-space {
    height: 0px;
  }
}
</style>
